<template>
  <div>
    <teleport to=".modal">
      <authorize-modal
        title="Audit Request Details"
        v-show="showAuditRequestDetailsModal"
        @hideModal="showAuditRequestDetailsModal = false"
      >
        <template #default>
          <div class="pt-5 pb-4 sm:pb-4 h-96 overflow-y-auto">
            <div id="details" class="mt-3 text-center sm:mt-0 sm:text-left">
              <div class="w-full my-6 details-holder text-sm">
                <div
                  :class="`receiver`"
                  class="w-full sm:w-3/4 text-left mt-6 text-sm"
                >
                  <p :class="`address`" class="font-medium w-full sm:w-3/4">
                    <b>{{ auditDetails.receiver }},</b> <br />
                    {{ auditDetails.company_name }}, <br />
                    {{ auditDetails.company_address }}.
                  </p>
                </div>
                <div :class="`salute`" class="my-6 text-left">Dear Sir,</div>
                <div :class="`heading`" class="text-center font-bold text-sm">
                  <b
                    >NOTIFICATION OF TAX AUDIT EXERCISE
                    {{ auditDetails.year_range }}</b
                  >
                </div>

                <div id="letter-body" :class="`paragraph-body text-sm`">
                  <div :class="`first-paragraph`" class="my-6 text-justify">
                    We hereby inform you that the Audit Staff of the
                    <b>Bayelsa State Board of Internal Revenue</b> are to carry
                    out back tax audit of your organization for the period
                    stated above.
                  </div>
                  <div :class="`second-paragraph`" class="text-justify">
                    The audit exercise will come up on the ……………………… to verify
                    your organization’s tax records in order to ascertain the
                    adequacy of your remittances of PAYE deductions from staff
                    salaries and Withholding tax on contracts and supplies in
                    pursuant to section 48(4) of the
                    <b>Personal Income Tax (Amendment) Act 2011.</b>
                  </div>
                  <div :class="`third-paragraph`" class="text-justify my-6">
                    Please, note that the attached list is for audit exercise
                    which you are expected to make available to the tax audit
                    team. It should be noted that submission of incorrect
                    documents or false information/report or any other action
                    that may be considered as an impediment to the success of
                    this tax audit exercise by your organization will attract
                    appropriate sanction and penalty in accordance with the
                    provisions of sections 94, 95 and 96 of the
                    <b>Personal Income Tax (Amendment) Act 2011.</b>
                  </div>
                  <div :class="`fourth-paragraph`" class="text-justify my-4">
                    We hope you will put in place all necessary arrangements to
                    ensure that the Tax Audit exercise is successfully carried
                    out and completed on or before ……………………….
                  </div>
                  <div :class="`fifth-paragraph`" class="text-justify my-4">
                    Alternatively you can send copies of the required documents
                    to
                    <b
                      >info@bir.by.gov.ng & ransome.omukoro@bir.by.gov.ng or
                      call 08037098762 or 08038834317</b
                    >
                    for further clarifications.
                  </div>
                </div>
                <div :class="`signature`" class="mt-20">
                  <img
                    :src="require('@/assets/chairman.png')"
                    alt="signature"
                    :class="`image-${auditDetails.id}`"
                    class="image w-52 object-cover"
                  />
                  <p class="font-bold">
                    <b>Dr. Daniel Eniekezimene</b><br /><i
                      >Executive Chairman</i
                    >
                  </p>
                  <!-- <p class="font-bold"><b>OVIE-IZIBE VICTOR</b></p> -->
                </div>
              </div>
            </div>
          </div>
        </template>
      </authorize-modal>
    </teleport>
    <content-holder :title="'Audits'" class="main-h overflow-y-scroll z-10">
      <alert-banner
        v-if="alert.state"
        :alert="alert"
        @close="alert.state = false"
      ></alert-banner>
      <div
        v-if="loadingData"
        class="w-full mt-32 flex items-center text-center"
      >
        <i
          :class="`lg:focus:shadow-sm w-12 mx-auto text-center fas fa-circle-notch mt-4 text-5xl text-blue-primary animate-spin`"
        ></i>
      </div>

      <div v-else-if="!loadingData && authorizedAudits.length < 1">
        <p
          class="flex items-center transition text-center text-white bg-blue-primary font-medium text-md md:text-lg w-full mb-2 px-4 py-4 rounded-md"
        >
          <span class="w-full">No audit requests at this moment...</span>
        </p>
      </div>
      <div v-else>
        <button
          type="button"
          @click="authorizeAll"
          class="w-full sm:w-auto bg-green-500 sm:rounded-full rounded-md mb-4 mt-6 px-3 py-1 text-white text-center font-medium focus:outline-none"
        >
          <div v-if="!authorizingAll">Authorize all</div>
          <div v-else class="flex items-center space-x-4 justify-center">
            <i class="fas fa-circle-notch animate-spin"></i>
            <span>Please wait...</span>
          </div>
        </button>
        <div class="mx-0 sm:mx-0 overflow-x-auto mt-6 sm:mt-0">
          <div class="py-2 align-middle inline-block min-w-full">
            <div
              class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      <span class="sr-only">Edit</span>
                      Actions
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Company name
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Address
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Year
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    @click="showRequestDetails(req)"
                    v-for="req in authorizedAudits"
                    :key="req.audit_ref"
                    class="cursor-pointer bg-white hover:bg-gray-100 hover:font-semibold"
                  >
                    <td
                      class="pr-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                    >
                      <div class="flex items-center space-x-4">
                        <button
                          type="button"
                          @click.stop="authorize(req.id)"
                          class="bg-green-500 rounded-full ml-2 px-3 py-1 text-white text-center font-medium focus:outline-none"
                        >
                          <div v-if="reqId != req.id">Authorize</div>
                          <div
                            v-else
                            class="flex items-center space-x-4 justify-center"
                          >
                            <i class="fas fa-circle-notch animate-spin"></i>
                            <span>Please wait...</span>
                          </div>
                        </button>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm font-medium text-gray-900">
                        {{ req.company_name }}
                      </div>
                    </td>
                    <td class="px-6 py-4 break-normal">
                      <div class="text-sm text-gray-900 w-80">
                        {{ req.company_address }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        {{ req.year_range }}
                      </div>
                    </td>
                  </tr>

                  <!-- More items... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </content-holder>
  </div>
</template>

<script>
import ContentHolder from "@/components/ContentHolder.vue";
import { auditMixin } from "@/mixins/audit.js";
import AlertBanner from "@/components/AlertBanner.vue";
import AuthorizeModal from "@/components/AuthorizeModal.vue";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  components: { ContentHolder, AlertBanner, AuthorizeModal },
  mixins: [auditMixin],
  data: () => ({
    authorizingSingle: false,
    reqId: 0,
    authorizingAll: false,
    loadingData: false,
    print: false,
    auditDetails: {},
    companyName: "",
    auditInterval: null,
    showAuditRequestDetailsModal: false,
  }),
  computed: {
    authorizedAudits() {
      return this.getAuditRequests;
    },
    printDate() {
      return moment().format("Do MMM, YYYY");
    },
  },
  methods: {
    getStatus(req) {
      return req.status === "pending" ? true : false;
    },
    printAudit(details) {
      //    console.log(details);
      this.auditDetails = details;
      this.print = true;
    },
    showRequestDetails(req) {
      console.log(req);
      this.auditDetails = req;
      this.showAuditRequestDetailsModal = true;
    },
    authorize(id) {
      this.stopFetchingAudits();
      this.authorizingSingle = true;
      this.reqId = id;
      this.authorizeSingleAudit({ ids: [id] })
        .then(() => {
          this.authorizingSingle = false;
          this.reqId = 0;
          this.startFetchingAudits();
        })
        .catch((err) => {
          if (
            !err.includes("Network Error") &&
            !err.toLowerCase().includes("timeout")
          ) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err,
            });
          }
          this.reqId = 0;
          this.authorizingSingle = false;
        });
    },
    authorizeAll() {
      this.authorizingAll = true;
      this.stopFetchingAudits();
      let ids = [];
      this.getAuditRequests.forEach((r) => {
        ids.push(r.id);
      });

      this.authorizeAllAudits({ ids: [...ids] })
        .then(() => {
          this.authorizingAll = false;
          this.showSuccess("All audit requests approved!!!");
          this.startFetchingAudits();
        })
        .catch((err) => {
          console.log(err);
          if (
            !err.includes("Network Error") &&
            !err.toLowerCase().includes("timeout")
          ) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err,
            });
          }
          this.authorizingAll = false;
        });
    },
    async stopFetchingAudits() {
      await clearInterval(this.auditInterval);
      //  console.log("unmounted");
    },
    fetchAuditRequests() {
      this.getAllAuditRequests({ status: ["pending"] })
        .then(() => {
          this.loadingData = false;
        })
        .catch((err) => {
          if (
            !err.includes("Network Error") &&
            !err.toLowerCase().includes("timeout")
          ) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err,
            });
          }
          this.loadingData = false;
        });
    },
    startFetchingAudits() {
      this.auditInterval = setInterval(() => {
        this.fetchAuditRequests();
      }, 10000);
    },
  },
  mounted() {
    this.loadingData = true;
    this.fetchAuditRequests();
    this.startFetchingAudits();
  },

  beforeUnmount() {
    this.stopFetchingAudits();
  },
};
</script>

<style></style>
